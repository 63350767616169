"use client";

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
} from '@apollo/client';
import { ErrorFeedback } from "../ui/error-feedback";
import { GQL_GET_SUB_DISTRICT_BY_DISTRICT_ID } from '../../gqls';
import { validator } from '../../utils';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';

const SelectSubDistrict = ({ formState, onValueChange, defaultValue, selectedAddress }) => {
  const districtId = selectedAddress?.value?.area_id

  const { t } = useTranslation();

  const { control, formState: formUtil } = formState;
  const { errors } = formUtil
  const { loading, data } = useQuery(GQL_GET_SUB_DISTRICT_BY_DISTRICT_ID, {
    variables: { districtId },
    context: {
      queryDeduplication: false,
      public: true,
    },
    fetchPolicy: 'network-only',
    skip: !districtId,
  });


  const mapOptionsToValues = (options) => {
    if (options?.length) {
      return options.map((option) => ({
        value: option,
        label: option.subDistrictName,
      }));
    }
    return [];
  };

  return (
    <div className="mb-0">
      <Controller
        name="subDistrict"
        control={control}
        rules={{ required: validator.required }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <Select
            id={name}
            name={name}
            className={`form-control-lg p-0 checkout-input ${
              Boolean(errors.subDistrict) ? 'is-invalid' : ''
            }`}
            styles={Boolean(errors.subDistrict) ? CustomStylesInvalid : CustomStyles}
            options={mapOptionsToValues(data?.getSubDistictKiriminAjabyId)}
            escapeClearsValue
            value={value}
            isLoading={loading}
            isDisabled={!districtId || loading}
            isSearchable={false}
            placeholder={t('placeholder.selectSubDistrict')}
            cacheOptions={false}
            onChange={(e) => {
              onChange(e);
              onValueChange({
                ...selectedAddress,
                value: {
                  ...selectedAddress?.value,
                  subDistrictId: e.value.id,
                  subDistrictName: e.value.subDistrictName,
                  districtId: e.value.districtId,
                }
              });
            }}
          />
        )}
      />
      <ErrorFeedback id="subDistrict-hint">{errors.subDistrict?.message}</ErrorFeedback>
    </div>
  );
};

SelectSubDistrict.defaultProps = {
  formState: {},
  defaultValue: null,
};

SelectSubDistrict.propTypes = {
  formState: PropTypes.object,
  defaultValue: PropTypes.object,
  onValueChange: PropTypes.func,
};

export default SelectSubDistrict;
