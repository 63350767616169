import './style.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../ui/label';
import { UseFormReturn } from 'react-hook-form';
import { Card } from '../ui/card';
import { validator, formatRp, membershipPeriods } from '../../utils';
import { IMembeshipTier, IMembershipTierPeriod } from '@/composables/membership.type';
import { Input } from '../ui/input';

type FormValue = {
  tierPeriod: IMembershipTierPeriod
}

type SelectPricesType = {
  selectedTier: IMembeshipTier,
  formState: UseFormReturn<FormValue>,
  onValueChange: (value: IMembershipTierPeriod) => void,
  isVisible: boolean
}

export const SelectPrices: React.FC<SelectPricesType> = ({ selectedTier, formState, onValueChange, isVisible }) => {
  const { t } = useTranslation();
  const { register, formState: formUtil } = formState || {};
  const { errors } = formUtil;

  const [sortedTierPeriod, setSortedTierPeriod] = useState<IMembershipTierPeriod[]>([]);

  useEffect(() => {
    if (selectedTier?.membershipTierPeriod) {
      const arr = [...selectedTier?.membershipTierPeriod];

      const sorted = arr?.sort((a, b) => a.monthPeriod - b.monthPeriod);

      setSortedTierPeriod(sorted);
    }
  }, [selectedTier]);

  return (
    <div>
      {isVisible ? (
        <div className="mt-[15px] p-0">
          <h5 className="mb-[15px] w-full text-center font-[600]">
            {t('form.membershipTiers')}
          </h5>
          <div className='flex flex-col gap-[10px]'>
            {sortedTierPeriod?.map((item) => {
              if (item?.status !== 'ACTIVE') return;

              return (
                <Card
                  className='py-[9.75px] px-4 shadow-none font-open-sans rounded-sm border-[1px]'
                  style={{
                    border: "1px solid rgba(0, 0, 0, .125)"
                  }}
                >
                  <Label
                    htmlFor={item?.id}
                    key={item?.id}
                    // check
                    className="cursor-pointer radio-prices-tier"
                  >
                    <div className="flex items-center">
                      <Input
                        id={item?.id}
                        // name="payment"
                        type="radio"
                        value={item?.amount}
                        {...register("tierPeriod", { required: validator.required })}
                        invalid={Boolean(errors.tierPeriod)}
                        className="mt-0 w-3 h-3 hidden"
                        onClick={() => onValueChange(item)}
                      />

                      <div className='mt-0 w-3 h-3 rounded-full border-[1px] border-gray-700' />

                      <span className="ml-[10px] leading-[23px] text-[13px] font-[400]">
                        {/* {formatRp(item?.amount)} {membershipPeriods[item?.monthPeriod as keyof typeof membershipPeriods]} */}
                        {formatRp(item.amount)}{' '}
                        {item.isLifetime
                          ? `/ ${t('word.forever')}`
                          : `/ ${item.monthPeriod} ${t('word.month')}`}
                        <br />
                      </span>
                      <span></span>
                    </div>
                  </Label>
                </Card>
                
              );
            })}
          </div>
          
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
