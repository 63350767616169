/* eslint-disable */
"use client";

import React, { useState, useEffect } from "react";
import "./style.scss";
import dynamic from 'next/dynamic';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ErrorFeedback } from "../ui/error-feedback";
import { Button } from "../ui/button";
import { MayarAlertBase } from "../ui/mayar-alert-base";
import {
  defaultChannelConfig,
  temporaryPaymentDisabled,
} from "../../utils";
import { Icon } from "@iconify/react";
import { Skeleton } from "../ui/skeleton";

const CustomerInputCookies = dynamic(() => import("@/components/forms-new/customer-input-cookies"), {
  ssr: false,
  loading: () => <Skeleton className="h-[80px] w-[95%] mx-auto rounded-xl mt-5 bg-[#f8f9fa]" />
});
const CustomFormBuilder = dynamic(() => import("../custom-form-builder"), {
  ssr: false,
});
import { CustomerInput } from ".";
import {
  SelectPayment,
  CustomerAddressInput,
  SelectShipping,
  FreebieInput,
} from ".";
import SelectTickets from "./select-tickets";
import { checkPaymentConfig } from "../../utils/payment";
import ProductQuantityInput from "./product-quantity-input";
import GivenCustomerInput from "./given-customer-input";
import SelectVariants from "./select-variants";
import SelectChapters from "./select-chapter";

const checkAvailPayment = (currentPayment = {}, amount, payChannelConfig) => {
  const configList = checkPaymentConfig(
    payChannelConfig?.config,
    defaultChannelConfig
  );
  const isCreditCardDisapproved = !(
    payChannelConfig?.isCreditCardApproved ?? false
  );
  const isAkulakuDisapproved = !(payChannelConfig?.isAkulakuApproved ?? false);

  const { type: currentType, code: currentCode } = currentPayment || {};
  const isCurrentPaymentDisabled = !(
    configList.find(
      (item) =>
        (item.code === currentCode || item.type === currentType) && item.status
    )?.status ?? false
  );

  return !(
    temporaryPaymentDisabled.includes(currentCode) ||
    isCurrentPaymentDisabled ||
    (currentType === "paylater" && isAkulakuDisapproved) ||
    (currentType === "card" && isCreditCardDisapproved) ||
    (currentType === "qris" && amount > 10_000_000) ||
    (currentType === "ewallet" && (amount < 100 || amount > 10_000_000)) ||
    (currentType === "ewallet" &&
      currentCode === "JENIUSPAY" &&
      amount < 1_000) ||
    (currentType === "va" && amount < 10_000) ||
    (currentType === "va" && currentCode === "BRI" && amount > 10_000_000) ||
    (currentType === "retail" && (amount < 10_000 || amount > 5_000_000)) ||
    (currentType === "retail" &&
      currentCode === "ALFAMART" &&
      amount > 2_500_000)
  );
};

export const OneClick = ({
  useForm = () => ({}),
  paymentLink = {},
  parentState = {},
  onShippingChange = () => {},
  onPaymentChange = () => {},
  onAddressChange = () => {},
  onLoadingShipment = () => {},
  onAmountProductChange = () => {},
  onTicketSelected = () => {},
  onVariantSelected,
  onChapterSelected,
  selectedChapter,
  selectedVariant,
  amountChangedAfterFee,
  newStyleDonate = "",
  cartData,
  specificAmount,
  setPurchasedQty,
  isDiscountUsed = false,
  isNotOneClick
}) => {
  const { t } = useTranslation();
  const crossSellPaymentLinksTotal = useSelector(
    (state) => state.totalCrossSell
  );
  // const basePath = process.env.NEXT_PUBLIC_BASE_PATH
  const basePath = null

  const form = useForm();
  const {
    selectShippingData,
    purchaseQty,
    finalAmount,
    isEmailReadOnly,
    selectedShipping,
    selectedPayment,
    // selectedTierPeriod,
    selectedAddress,
    loadingQuantity,
    amountProduct,
    defaultCustomerCookies,
    invalidTickets,
    userId,
    setPurchaseLimit,
    setOutOfStock,
  } = parentState;
  const currentUser = useSelector((state) => state.user);

  const {
    name,
    email,
    mobile,
    address,
    completeAddress,
    shippingData,
    paymentData,
  } = defaultCustomerCookies;

  const [correctAmount, setCorrectAmount] = useState(
    amountChangedAfterFee ? amountChangedAfterFee : finalAmount
  );
  const [errorShipping, setErrorShipping] = useState(null);

  const [isCustInputVisible, setIsCustInputVisible] = useState(false);
  const [isShippingVisible, setIsShippingVisible] = useState(false);
  const [isPaymentVisible, setIsPaymentVisible] = useState(false);
  const [isPricesVisible, setIsPricesVisible] = useState(
    // Boolean(selectedTierPeriod)
  );

  const [errorPayment, setErrorPayment] = useState(null);
  // const [initState, setInitState] = useState(true);

  const [isBtnChoosePaymentVisible, setIsBtnChoosePaymentVisible] =
    useState(false);
  // const [isBtnChoosePricesVisible, setIsBtnChoosePricesVisible] = useState(false);

  useEffect(() => {
    if (amountChangedAfterFee) {
      setCorrectAmount(amountChangedAfterFee);
    } else {
      setCorrectAmount(finalAmount);
    }
  }, [amountChangedAfterFee, finalAmount]);

  // should be optimized
  useEffect(() => {
    if (isNotOneClick) {
      onShippingChange(null);
      onPaymentChange(null);
      onAddressChange(null);
      setIsCustInputVisible(true);
      setIsShippingVisible(true);
      setIsPaymentVisible(false);
      setIsPricesVisible(false);
      setIsBtnChoosePaymentVisible(true);
      // setIsBtnChoosePricesVisible(true);
    } else {
      onShippingChange(shippingData);
      onPaymentChange(paymentData);
      onAddressChange(address);
    }
    // setInitState(true);
  }, []);

  const toggleShipping = (bool) => {
    if (parseInt(purchaseQty) > 0) {
      setIsShippingVisible(bool ?? !isShippingVisible);
      setErrorShipping(null);
    } else {
      setErrorShipping(true);
    }
  };

  const handleSelectVariant = (val) => {
    onVariantSelected(val);
    // setIsBtnChoosePricesVisible(true);
    setIsPricesVisible(false);
  };

  const handleSelectChapter = (val) => {
    onChapterSelected(val);
    // setIsBtnChoosePricesVisible(true);
    setIsPricesVisible(false);
  };

  const togglePayment = (bool) => {
    if (
      paymentLink?.type === "physical_product" &&
      parseInt(purchaseQty) <= 0
    ) {
      setErrorPayment(true);
    } else {
      setIsPaymentVisible(bool ?? !isPaymentVisible);
      setErrorPayment(null);
    }
  };

  useEffect(() => {
    if (!selectedShipping && !selectedPayment) {
      toggleShipping(true);
      togglePayment(false);
      setIsBtnChoosePaymentVisible(true);
      // setIsBtnChoosePricesVisible(true);
    }
  }, [selectedShipping]);

  useEffect(() => {
    if (isBtnChoosePaymentVisible && finalAmount > 0) {
      form.register("payment", { required: true });
    } else {
      form.unregister("payment");
    }
  }, [isBtnChoosePaymentVisible, finalAmount]);

  useEffect(() => {
    if (form.formState?.isSubmitting) {
      if (
        !selectedPayment &&
        finalAmount > 0
      ) {
        form.setError("payment", { type: "manual", message: "" });
      } else {
        form.clearErrors("payment");
      }
    }
  }, [form.formState?.isSubmitting, selectedPayment]);

  useEffect(() => {
    if (
      (paymentLink?.type !== "physical_product" ||
        (paymentLink?.type === "physical_product" &&
          parseInt(purchaseQty) > 0)) &&
      selectedPayment
    ) {
      const isPaymentAvailable = checkAvailPayment(
        selectedPayment,
        finalAmount,
        paymentLink?.payChannelConfig
      );

      if (!isPaymentAvailable) {
        togglePayment();
        onPaymentChange(null);
      }
    }
  }, [finalAmount]);

  useEffect(() => {
    if (
      (finalAmount === 0 && crossSellPaymentLinksTotal === 0)
    ) {
      onPaymentChange(null);
      setIsPaymentVisible(false);
      setIsBtnChoosePaymentVisible(false);
    } else if (finalAmount >= 1000 && !isPaymentVisible && !selectedPayment) {
      setIsBtnChoosePaymentVisible(true);
    }
  }, [finalAmount, isPaymentVisible, selectedPayment]);

  useEffect(() => {
    if (selectedPayment) {
      setIsBtnChoosePaymentVisible(false);
    }
  }, [selectedPayment]);

  const toggleCustInput = () => setIsCustInputVisible(!isCustInputVisible);

  return (
    <>
      {paymentLink.type === "saas" && (
        <ProductQuantityInput
          paymentLink={paymentLink}
          formState={form}
          qty={purchaseQty}
          changeQty={setPurchasedQty}
          changeLimit={setPurchaseLimit}
          setOutOfStock={setOutOfStock}
          isProductTypeUnlimited={Boolean(paymentLink.type === "membership")}
        />
      )}

      {(!paymentLink?.type || paymentLink?.type !== "invoice") && (
        <>
          {isCustInputVisible ? (
            <>
              <CustomerInput
                styleDonate={newStyleDonate}
                formState={form}
                defaultValues={defaultCustomerCookies}
                isEmailReadOnly={isEmailReadOnly}
                paymentLinkType={paymentLink?.type}
                paymeLink={paymentLink?.user?.paymeLink}
              />
              {paymentLink?.type === "physical_product" && (
                <CustomerAddressInput
                  formState={form}
                  defaultValues={defaultCustomerCookies}
                  onAddressChange={(value) => {
                    onAddressChange(value);
                    onShippingChange(null);
                    onPaymentChange(null);
                  }}
                />
              )}
            </>
          ) : (
            <CustomerInputCookies
              name={name}
              email={email}
              mobile={mobile}
              address={address}
              completeAddress={completeAddress}
              paymentLink={paymentLink}
              toggleCustInput={toggleCustInput}
            />
          )}
          <FreebieInput
            paymentType={paymentLink?.paymentType}
            amountMin
            formState={form}
            amount={amountProduct}
            specificAmount={specificAmount}
            onAmountChange={onAmountProductChange}
            paymeLink={paymentLink?.user?.paymeLink}
            suggestedAmount={paymentLink?.checkoutSettings?.recommendedAmount}
          />
          {paymentLink?.form && (
            <CustomFormBuilder form={paymentLink.form} formState={form} />
          )}
        </>
      )}

      <SelectTickets
        paymentLink={paymentLink}
        onTicketSelected={onTicketSelected}
        formState={form}
        isDisabled={isEmailReadOnly}
        invalid={invalidTickets}
        isDiscountUsed={isDiscountUsed}
      />

      {selectedVariant && (
        <SelectVariants
          variant={paymentLink.variant}
          selectedVariant={selectedVariant}
          onVariantSelected={handleSelectVariant}
          paymentLinkLink={paymentLink.link}
        />
      )}

      {selectedChapter && (
        <SelectChapters
          writingPaymentMethod={paymentLink.writingPaymentMethod}
          chapters={paymentLink.writingChapters}
          selectedChapter={selectedChapter}
          onChapterSelected={handleSelectChapter}
          paymentLinkLink={paymentLink.link}
          paymentLink={paymentLink}
        />
      )}

      {paymentLink.type === "writing"
        && paymentLink.writingType === "CHAPTER"
        && !paymentLink.writingChapters.length
        && paymentLink?.writingPaymentMethod !== "ALL"
        && (
          <div className="w-full pt-[20px] px-[10px]">
            <MayarAlertBase variant="light" className="text-black text-center">
              {t('alert.unactiveTier')}
            </MayarAlertBase>
          </div>
        )
      }

      {paymentLink?.type === "physical_product" &&
        selectedAddress?.value?.area_id && (
          <div className="w-full pt-[20px] px-[10px]">
            {!isShippingVisible && (
              <>
                <div className="flex flex-col items-start justify-center relative align-middle" vertical>
                  <Button
                    onClick={() => toggleShipping()}
                    block
                    outline
                    variant="light"
                    className="flex w-full py-9 justify-between items-center pl-[30px] text-black border-gray rounded-b-none"
                  >
                    <img
                      src={selectedShipping?.logo_url}
                      alt="logo"
                      height="30"
                      className="ml-[10px]"
                    />
                    <span className="text-center mr-[10px]">
                      {selectedShipping?.name} {selectedShipping?.rate_name}{" "}
                      <small>
                        {selectedShipping?.min_day === selectedShipping?.max_day
                          ? `(${selectedShipping?.max_day || 1} ${t("word.day")})`
                          : `(${selectedShipping?.min_day}-${selectedShipping?.max_day} ${t(
                              "word.day"
                            )})`}
                      </small>
                    </span>
                  </Button>
                  <Button
                    className="text-center py-4 border-gray mt-0 rounded-t-none border-t-0 w-full font-semibold uppercase"
                    variant="light"
                    block
                    type="button"
                    onClick={() => toggleShipping()}
                  >
                    {t("form.changeShipping")}
                  </Button>
                </div>
                <div className={Boolean(errorShipping) ? "is-invalid" : ""} />
                <ErrorFeedback>{Boolean(errorShipping) && t("form.quantityWarning")}</ErrorFeedback>
              </>
            )}

            <SelectShipping
              data={selectShippingData}
              formState={form}
              isVisible={isShippingVisible}
              loadingQuantity={loadingQuantity}
              initShipping={onShippingChange}
              onLoadingShipment={onLoadingShipment}
              changeVisibility={() => toggleShipping(true)}
              onValueChange={(value) => {
                onShippingChange(value);
                toggleShipping();
              }}
              userId={userId}
              // cartData={cartData}
            />
          </div>
        )}

      <GivenCustomerInput
        formState={form}
        isSupportGift={paymentLink?.checkoutSettings?.isSupportGift}
      />

      {(paymentLink?.type !== "physical_product" && amountProduct >= 1000) ||
      crossSellPaymentLinksTotal >= 1000 ||
      (cartData && amountProduct >= 10000) ||
      (paymentLink?.type === "physical_product" && selectedShipping?.name) ? (
        <div className="w-full pt-[20px] px-[10px] mb-[15px]">
          {selectedPayment && (
            <>
              <div className="flex flex-col items-start justify-center relative align-middle" vertical>
                <Button
                  variant="outline"
                  onClick={() => {
                    togglePayment();
                    onPaymentChange(null);
                  }}
                  block
                  outline
                  color="light"
                  type="button"
                  className="flex w-full p-[15px] justify-between items-center pl-[30px] h-auto text-black border-gray rounded-b-none rounded-t-sm"
                >
                  {selectedPayment?.type === "qris" ? (
                    <>
                      <img
                        alt="Logo QRIS"
                        src={`${basePath ? basePath : ""}/QrisBI.svg`}
                        width="130"
                        className="just-mobile"
                      />
                      <img
                        alt="Logo QRIS"
                        src={`${basePath ? basePath : ""}/qris.svg`}
                        width="190"
                        className="just-tablet"
                      />
                    </>
                  ) : (
                    <img
                      alt={`Logo ${selectedPayment?.name}`}
                      src={selectedPayment?.img.replace(/.png/, ".svg")}
                      width={selectedPayment?.imgWidth}
                      className="ml-[12px]"
                    />
                  )}

                  <span className="mr-[12px] text-[11px] font-[600] uppercase">{selectedPayment?.name}</span>
                </Button>
                <Button
                  className="text-center py-4 border-gray mt-0 rounded-t-none border-t-0 w-full uppercase rounded-b-sm text-[#212529] text-[11px] font-open-sans font-[600]"
                  variant="light"
                  block
                  type="button"
                  onClick={() => {
                    togglePayment();
                    onPaymentChange(null);
                  }}
                >
                  {t("form.changePayment")}
                </Button>
              </div>
              <div className={Boolean(errorPayment) ? "is-invalid" : ""} />
              <ErrorFeedback>{Boolean(errorPayment) && t("form.quantityWarning")}</ErrorFeedback>
            </>
          )}

          {isBtnChoosePaymentVisible && (
            <>
              <Button
                className="text-center border-gray btn-choose-payment w-full py-4 uppercase font-[500] text-[11px] rounded-sm"
                variant="light"
                block
                type="button"
                onClick={() => {
                  togglePayment();
                  setIsBtnChoosePaymentVisible(false);
                }}
              >
                {t("form.choosePayment")} &nbsp; <Icon icon="tabler:chevron-down" />
              </Button>
              <div
                className={Boolean(form.errors?.payment) ? "is-invalid" : ""}
              />
              <ErrorFeedback>{Boolean(form.errors?.payment) && t("checkout.paymentWarning")}</ErrorFeedback>
            </>
          )}

          <SelectPayment
            payChannelConfig={currentUser?.payChannelConfig}
            formState={form}
            amount={correctAmount}
            isVisible={isPaymentVisible}
            onValueChange={(value) => {
              onPaymentChange(value);
              togglePayment();
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

OneClick.propTypes = {
  newStyleDonate: PropTypes.string,
  paymentLink: PropTypes.object.isRequired,
  parentState: PropTypes.object.isRequired,
  useForm: PropTypes.func.isRequired,
  onShippingChange: PropTypes.func,
  onPaymentChange: PropTypes.func,
  onAddressChange: PropTypes.func,
  onLoadingShipment: PropTypes.func,
  onAmountProductChange: PropTypes.func,
  onTicketSelected: PropTypes.func,
  // isNotOneClick: PropTypes.bool,
};
