import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GQL_GET_TOTAL_SOLD_ORDER } from '../../gqls';
import { parseJSON, formatRp, validator } from '../../utils';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import { sanitizeDOM } from '@/utils/sanitizer';
import { ErrorFeedback } from '../ui/error-feedback';

import { PaymentLinkVariant } from '@/composables/paymentlink.type';
import { ReactSelectOption } from '@/common/types/option.types';
import { DetailRemainingStock } from '@/common/types/stock.types';

type SelectVariantType = {
  variant: string;
  paymentLinkLink: string;
  onVariantSelected: (variant: PaymentLinkVariant) => void;
  selectedVariant: PaymentLinkVariant;
}

type ExtendedPaymentLinkVariant = PaymentLinkVariant & {
  isSoldOut: boolean;
};

const regex = /(<([^>]+)>)/gi;

const Variant: React.FC<{ variant: ExtendedPaymentLinkVariant }> = ({ variant }) => {
  const { t } = useTranslation();
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMoreClick = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
  };

  // Remove the html tag so the text is trimming the correct sentence
  const pureTextDescription = variant.description?.replace(regex, '');

  useEffect(() => {
    setIsReadMore(false);
  }, [variant]);

  return (
    <div className="card-variant-item border-0 m-0 p-0 sidebar-variant">
      <div className="ticket m-0">
        <div className="ticket__left"></div>
        <div className="ticket__content">
          <div className="ticket--title mb-[10px]">
            <h4 className="ticket--name m-0">{variant.name}</h4>
            {variant.isSoldOut && <h4 className="text-red-500 m-0">{t('word.soldOut')}</h4>}
          </div>

          <h5 className="mb-[25px] text-primary">{formatRp(variant.amount)}</h5>

          <div className="ticket--line" />

          <div
            className="text-[14px] desc-editor reset-tailwind-override-htmltag"
            dangerouslySetInnerHTML={{
              __html: sanitizeDOM(
                !isReadMore && pureTextDescription?.length > 100
                  ? `${pureTextDescription.slice(0, 100)}...`
                  : variant.description,
              ),
            }}
          />

          {pureTextDescription?.length >= 100 && !isReadMore && (
            <div
              className="readmore-tier-desc"
              onClick={toggleReadMoreClick}
              onKeyDown={toggleReadMoreClick}
              role="presentation"
            >
              + {t('global.readMore2')}
            </div>
          )}

          {pureTextDescription?.length >= 100 && isReadMore && (
            <div
              className="readmore-tier-desc"
              onClick={toggleReadMoreClick}
              onKeyDown={toggleReadMoreClick}
              role="presentation"
            >
              - {t('global.readLess')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectVariants: React.FC<SelectVariantType> = ({ variant, paymentLinkLink, onVariantSelected, selectedVariant }) => {
  const { t } = useTranslation();
  const { formState, control } = useFormContext();
  const { errors } = formState
  const variants: ExtendedPaymentLinkVariant[] = parseJSON(variant, [] as any);
  const variantOptions = variants.map((item) => ({ value: item.id, label: item.name }));
  const [currentVariant, setCurrentVariant] = useState<ExtendedPaymentLinkVariant>();

  const { data: historyData } = useQuery(GQL_GET_TOTAL_SOLD_ORDER, {
    variables: { link: paymentLinkLink },
    context: { public: true },
  });

  const variantHistory = get(historyData, 'getTotalSoldOrder');

  useEffect(() => {
    if (selectedVariant?.id) {
      const updateVariant = variants.find(({ id }) => id === selectedVariant?.id);
      const total = variantHistory?.detail?.find((v: DetailRemainingStock) => v.id === selectedVariant?.id)?.qty || 0;

      if (updateVariant) {
        updateVariant.isSoldOut = Boolean(updateVariant.limit && total >= updateVariant.limit);
        onVariantSelected(updateVariant);
        setCurrentVariant(updateVariant);
      }
    }
  }, [historyData]);

  if (variants.length) {
    return (
      <div className='w-full pt-[20px] px-[10px]'>
        <h5 className="mb-[15px] w-full text-center">
          <strong>Pilih Varian Produk</strong>
        </h5>

        <div className='mb-[20px]'>
          <Controller
            name="variant"
            control={control}
            rules={{ required: validator.required }}
            defaultValue={
              variantOptions?.find((item: ReactSelectOption) => item.value === selectedVariant?.id) || null
            }
            render={({ field, fieldState, formState }) => (
              <Select
                id={field.name}
                name={field.name}
                placeholder={t('word.variant')}
                options={variantOptions}
                value={field.value}
                styles={Boolean(formState.errors.variant) ? CustomStylesInvalid : CustomStyles}
                className={`form-control-lg p-0 checkout-input ${
                  Boolean(formState.errors.variant) ? 'is-invalid' : ''
                }`}
                onChange={(e) => {
                  const updatedVariant = variants.find(({ id }) => id === e.value);
                  const total = variantHistory?.detail?.find((v: DetailRemainingStock) => v.id === e.value)?.qty || 0;

                  if (updatedVariant) {
                    updatedVariant.isSoldOut = Boolean(updatedVariant.limit && total >= updatedVariant.limit);

                    field.onChange(e);
                    onVariantSelected(updatedVariant);
                    setCurrentVariant(updatedVariant);
                  }
                }}
              />
            )}
          />
          <ErrorFeedback id="variant-hint">{errors?.variant && t('form.variantWarning')}</ErrorFeedback>
        </div>

        {currentVariant ? (
          <Variant variant={currentVariant} />
        ) : (
          ''
        )}
      </div>
    );
  }

  return null;
};

SelectVariants.defaultProps = {
  variant: '',
  onVariantSelected: () => {},
};

export default SelectVariants;
