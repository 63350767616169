import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { ErrorFeedback } from '../ui/error-feedback';
import { validator } from '../../utils';
import SelectAddress from '../selector/select-address';
import SelectSubDistrict from '../sub-district-selector';

export const CustomerAddressInput = ({ formState, defaultValues, onAddressChange, selectedAddress }) => {
  const { t } = useTranslation();
  const { register, formState: formUtil } = formState;
  const { errors } = formUtil;

  return (
    <>
      <div className="text-center pt-[20px] px-[10px] w-full mt-[10px]">
        <h5 className="mb-0 checkout-sub-title text-[14px]">
          <strong>{t('form.fullAddress')}</strong>
        </h5>
      </div>
      <div className='pt-[20px] px-[10px] w-full'>
        <SelectAddress
          onValueChange={onAddressChange}
          formState={formState}
          defaultValue={defaultValues.address}
        />
      </div>
      <div className='pt-[20px] px-[10px] w-full'>
        <SelectSubDistrict
          onValueChange={onAddressChange}
          formState={formState}
          defaultValue={defaultValues.subDistrict}
          selectedAddress={selectedAddress}
        />
      </div>
      <div className='pt-[20px] px-[10px] w-full'>
        <Textarea
          id="completeAddress"
          name="completeAddress"
          type="textarea"
          autoComplete="on"
          aria-describedby="complete-address-hint"
          className={cn(
            "form-control select-shipping-input !min-h-[150px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px]",
            errors?.completeAddress ?
              "!border-[#fac6cc] !bg-[#fef9fa] border-[1px]"
              : "border-[#e6ecf0] bg-[#fbfcfc]"
          )}
          placeholder={t('placeholder.fullAddress')}
          invalid={Boolean(errors.completeAddress)}
          defaultValue={defaultValues.completeAddress}
          {...register("completeAddress", {
            required: validator.required,
            minLength: validator.minLength(5),
            maxLength: validator.maxLength(199),
          })}
        />
        <ErrorFeedback id="complete-address-hint">
          {errors?.completeAddress?.message && errors?.completeAddress?.message}
        </ErrorFeedback>
      </div>
    </>
  );
};

CustomerAddressInput.defaultProps = {
  formState: {},
  defaultValues: {},
};

CustomerAddressInput.propTypes = {
  formState: PropTypes.object,
  defaultValues: PropTypes.object,
  onAddressChange: PropTypes.func.isRequired,
};
