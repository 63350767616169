/* eslint-disable jsx-a11y/no-autofocus */
"use client";

import React from 'react';
import { UseFormReturn } from "react-hook-form"
import { useTranslation } from 'react-i18next';
import { validator } from '../../utils';
import { Input } from '@/components/ui/input';
import { ErrorFeedback } from '../ui/error-feedback';
import { cn } from '@/lib/utils';
import { PaymentLinkType } from '@/composables/paymentlink.type';
type FormType = {
  email: string;
  mobile: string;
  name: string;
}

type CustomerInputType = {
  formState: UseFormReturn<FormType>;
  defaultValues: FormType;
  isEmailReadOnly: boolean;
  paymentLinkType: PaymentLinkType;
  styleDonate: string;
  paymeLink: string;
}

export const CustomerInput: React.FC<CustomerInputType> = ({
  formState,
  defaultValues,
  isEmailReadOnly,
  paymentLinkType,
  styleDonate,
  paymeLink
}) => {
  const { t } = useTranslation();
  const { register, formState: formUtil } = formState;
  const { errors } = formUtil

  let isHeaderVisible = true;
  let headerTranslation = '';

  function inIframe() {
    try {
      if (typeof window !== "undefined") return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  switch (paymentLinkType) {
    case 'payme':
      headerTranslation = 'form.yourData';
      break;
    case 'zakat':
    case 'fundraising':
      headerTranslation = 'form.donorDetails';
      break;
    case 'physical_product':
      headerTranslation = 'form.buyerDetails';
      break;
    default:
      isHeaderVisible = false;
      break;
  }

  return (
    <>
      {isHeaderVisible && (
        <div className="text-center w-full pt-[20px] px-[10px]'">
          <h5 className="mb-0 checkout-sub-title text-[14px] font-[600]">
            {t(headerTranslation)}
          </h5>
        </div>
      )}
      <div className="w-full pt-[20px] px-[10px]">
        <Input
          id="email"
          // name="email"
          type="email"
          // bsSize="lg"
          autoComplete="email"
          aria-describedby="email-hint"
          // temporary hardcode for pemuda hijrah
          placeholder={paymeLink === "pemudahijrah" ? "Email Aktif" : "Email"}
          readOnly={isEmailReadOnly}
          autoFocus={!inIframe() && !defaultValues.email}
          defaultValue={defaultValues.email}
          invalid={Boolean(errors?.email)}
          {...register('email', {
            required: validator.required,
            pattern: validator.email,
          })}
          className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] text-[1rem] border-[1px] border-[#e6ecf0] !rounded-sm"
        />
        <ErrorFeedback id="email-hint">{errors?.email?.message}</ErrorFeedback>
      </div>
      <div className={cn(
        'w-full pt-[20px] px-[10px]',
        styleDonate
      )}>
        <Input
          id="name"
          // name="name"
          type="text"
          // bsSize="lg"
          autoComplete="name"
          aria-describedby="name-hint"
          // temporary hardcode for pemuda hijrah
          placeholder={paymeLink === "pemudahijrah" ? "Nama Panggilan" : t('word.name')}
          defaultValue={defaultValues.name}
          invalid={Boolean(errors?.name)}
          {...register('name', {
            required: validator.required,
            minLength: validator.minLength(3),
          })}
          className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] text-[1rem] border-[1px] border-[#e6ecf0] !rounded-sm"
        />
        <ErrorFeedback id="name-hint">{errors?.name?.message}</ErrorFeedback>
      </div>
      <div className="w-full pt-[20px] px-[10px]">
        <Input
          id="mobile"
          // name="mobile"
          type="tel"
          // bsSize="lg"
          autoComplete="tel"
          aria-describedby="tel-hint"
          // temporary hardcode for pemuda hijrah
          placeholder={paymeLink === "pemudahijrah" ? "No Whatsapp" : t('placeholder.phone')}
          defaultValue={defaultValues.mobile}
          invalid={Boolean(errors?.mobile)}
          {...register('mobile', {
            required: validator.required,
            setValueAs: validator.formatPhone,
            pattern: validator.phone,
            minLength: validator.minLength(10),
            maxLength: validator.maxLength(15),
          })}
          className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] text-[1rem] border-[1px] border-[#e6ecf0] !rounded-sm"
        />
        <ErrorFeedback id="tel-hint">{errors?.mobile?.message}</ErrorFeedback>
      </div>
    </>
  );
};
