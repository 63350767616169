import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { Card } from '../ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Input } from '../ui/input';
// import SlideToggle from 'react-slide-toggle';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ErrorFeedback } from '../ui/error-feedback';
import { GQL_GET_RATE_SHIPMENT_ORDER_NEW } from '../../gqls';
import { formatRp, validator, decrypt } from '../../utils';
// import Icon from '../icon';
import { Icon } from '@iconify/react';

export const SelectShipping = ({
  data,
  formState,
  onValueChange,
  isVisible,
  loadingQuantity,
  initShipping,
  changeVisibility,
  onLoadingShipment = () => {},
}) => {
  const { t } = useTranslation();
  const { register, formState: formUtil } = formState;
  const { errors } = formUtil;
  const { paymentLink, quantity, address, couponCode, sku, selectedShipping } = data;
  const [shippingRates, setShippingRates] = useState(null);
  const [toggleSlideEvent, setToggleSlideEvent] = useState({});

  const defaultCustomerCookies = Cookies.get('customerInput')
    ? decrypt(Cookies.get('customerInput'))
    : {};

  const [getRateShipment, { loading, data: rate }] = useLazyQuery(GQL_GET_RATE_SHIPMENT_ORDER_NEW, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'network-only',
    context: { public: true },
    notifyOnNetworkStatusChange: true,
  });

  const createToggleEvent = (object) => {
    const temp = {};
    Object.keys(object).forEach((objKey) => {
      temp[objKey] = { value: 0, toggle: false };
    });

    return temp;
  };

  const handleToggleEvent = (objKeys) => {
    const temp = { ...toggleSlideEvent };
    temp[objKeys] = {
      value: Date.now(),
      toggle: !temp[objKeys].toggle,
    };
    setToggleSlideEvent(temp);
  };

  useEffect(() => {
    onLoadingShipment(loading);
  }, [loading]);

  useEffect(() => {
    if (address?.area_id && Boolean(quantity) && Boolean(sku)) {
      getRateShipment({
        variables: {
          input: {
            id: paymentLink?.id,
            destinationAreaId: address?.area_id,
            subDistrictId: address?.subDistrictId,
            quantity,
            sku,
            couponCode,
          },
        },
      });
    }

    if (!address?.area_id) {
      setShippingRates(null);
      setToggleSlideEvent(null);
    }
  }, [address, isVisible, quantity, sku]);

  useEffect(() => {
    if (rate?.getRateShipmentKiriminAja?.logistic) {
      if (defaultCustomerCookies?.isOneClick) {
        const tempShipping = rate?.getRateShipmentKiriminAja?.logistic[
          selectedShipping?.logisticType
        ]?.find(({ rate_name }) => rate_name === selectedShipping?.rate_name);

        if (tempShipping) {
          initShipping({ ...tempShipping, logisticType: selectedShipping?.logisticType });
        } else {
          changeVisibility();
        }
      }

      setShippingRates(rate.getRateShipmentKiriminAja);
      setToggleSlideEvent(createToggleEvent(rate.getRateShipmentKiriminAja.logistic));
    } else {
      setShippingRates(null);
      setToggleSlideEvent(null);
    }
  }, [rate]);

  useEffect(() => {
    if (rate?.getRateShipmentKiriminAja?.logistic) {
      setToggleSlideEvent(createToggleEvent(rate?.getRateShipmentKiriminAja?.logistic));
    }
  }, [isVisible]);

  return (
    <div>
      {isVisible && (
        <>
          {(loading || loadingQuantity) && (
            <div className="my-[20px]">
              <span className="text-secondary text-[14px] text-center px-[20px] m-0 flex justify-center items-center gap-2">
                {t('checkout.loadExpedition')} <Icon icon="svg-spinners:3-dots-fade" />
              </span>
            </div>
          )}

          {shippingRates?.status === 'success' && !loadingQuantity && !loading && (
            <div className="mt-[15px] p-0">
              <h5 className="mb-15 w-100 text-center checkout-sub-title text-[14px] font-[600]">
                {t('checkout.chooseExp')}
              </h5>

              <Accordion type="single" collapsible className='pt-[20px]'>
                {Object.values(shippingRates.logistic).map((logistic, logisticIndex) => {
                  const logisticType = Object.keys(shippingRates.logistic)[logisticIndex];

                  if (logistic?.length > 0 && typeof logistic === "object") {
                    return (
                      <AccordionItem value={`${logisticIndex}-${logistic.name}`} key={`${logisticIndex}-${logistic.name}`} className='m-0 border-0'>
                        <AccordionTrigger className='m-0 border-0 py-1' noArrow={true}>
                          <Button
                            className="py-[15.5px] px-[15px] text-secondary flex items-center w-full justify-between checkout-select-payment text-[12px] uppercase rounded-sm"
                            size="lg"
                            type='button'
                            // color="light"
                            variant="light"
                            onClick={() => handleToggleEvent(logisticType)}
                          >
                            {`${logisticType.charAt(0).toUpperCase()}${logisticType.substring(1)}`}{' '}
                            <Icon
                              // name={`chevron-${
                              //   toggleSlideEvent[logisticType].toggle ? 'down' : 'right'
                              // }`}
                              icon={`heroicons-outline:chevron-${
                                toggleSlideEvent[logisticType].toggle ? 'down' : 'right'
                              }`}
                              className="rui-icon-collapse"
                            />
                          </Button>
                        </AccordionTrigger>
                        <AccordionContent className='p-0'>
                          {logistic?.map((courier, index) => (
                            <Label
                              for={`${logisticType}-${courier.rate_name}`}
                              key={index}
                              check
                              className="cursor-pointer mb-0"
                            >
                              <Card className="flex mb-[10px] justify-between items-center select-payment-item sm:py-[0.2rem] py-[0.6rem] px-[1.25rem] shadow-none text-[15px] sm:text-[13px] font-light rounded-sm text-secondary">
                                <div className='flex items-center gap-3'>
                                  <Input
                                    id={`${logisticType}-${courier.rate_name}`}
                                    name="shipping"
                                    type="radio"
                                    value={`${logisticType}-${courier.rate_name}`}
                                    {...register("shipping", { required: validator.required })}
                                    className="mt-0 w-[13px]"
                                    invalid={Boolean(errors?.shipping)}
                                    onClick={() => onValueChange({ ...courier, logisticType })}
                                  />
                                  <img
                                    src={courier.logo_url}
                                    alt="logo"
                                    // height="30"
                                    className="ml-[0px] h-[30px]"
                                  />
                                  <span className="text-center">
                                    {courier.service_name} <br />
                                    <small>
                                      {courier.min_day === courier.max_day
                                        ? `(${courier.max_day || 1} ${t('word.day')})`
                                        : `(${courier.min_day}-${courier.max_day} ${t(
                                            'word.day',
                                          )})`}
                                    </small>
                                  </span>
                                  <span className='relative'>
                                    {formatRp(
                                      courier.compulsory_insurance
                                        ? courier.finalRate +
                                            courier.insuranceRate -
                                            courier.discount
                                        : courier.finalRate - courier.discount,
                                    )}
                                  </span>
                                </div>
                              </Card>
                            </Label>
                          ))}
                        </AccordionContent>
                      </AccordionItem>
                    )
                    
                  }
                  return null
                })}
              </Accordion>
              <div className={Boolean(errors?.shipping) ? 'is-invalid' : ''} />
              <ErrorFeedback id="shipping-hint">
                {Boolean(errors?.shipping) && t('checkout.expeditionWarning')}
              </ErrorFeedback>
            </div>
          )}

          {!loading && address?.area_id && !rate?.getRateShipmentKiriminAja?.logistic && (
            <div className="my-[20px] -mx-[5px] flex flex-wrap justify-center">
              <span className="text-red-500 text-[14px] text-center px-[20px] m-0">
                {rate?.getRateShipmentKiriminAja?.status === 'out_of_stock'
                  ? `${t('alert.outStock')} ${t('alert.refresh')}`
                  : t('checkout.noExpedition')}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

SelectShipping.defaultProps = {
  isVisible: true,
  data: {},
};

SelectShipping.propTypes = {
  formState: PropTypes.object,
  data: PropTypes.object,
  isVisible: PropTypes.bool,
  onValueChange: PropTypes.func,
};
